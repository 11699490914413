<template>
  <div class="activitiesSquare wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">政务服务</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/ActivitiesSquare' }">活动广场</el-breadcrumb-item>
      <el-breadcrumb-item>活动详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="box">
        <div class="item">
          <img :src="item.activityUrl" alt="" class="img" />
          <div class="info">
            <div class="titles">{{ item.activityName }}</div>
            <div class="labels">
              <div v-for="(v, i) in (item.activityLabel || '').split(',')" :key="i">
                <div class="label" v-if="v">{{ v }}</div>
              </div>
            </div>
            <div class="texts">活动时间: {{ item.activityDate }}</div>
            <div class="texts">举办地点: {{ item.activityAddress }}</div>
            <div class="texts">举办单位: {{ item.sponsor }}</div>
          </div>
          <div class="item-right">
            <div class="time">发布时间 ： {{ item.pushTime }}</div>
            <div class="top">
              <img src="../../../assets/img/icon_rs@2x.png" alt="" class="rs" />
              已报名 <span>{{ item.signUpNum }}</span
              >人
            </div>
            <div class="btn2" v-if="item.activityStatus == 1">已报名</div>
            <div class="btn1" v-if="item.activityStatus == 2" @click="signUp">可报名</div>
            <div class="btn3" v-if="item.activityStatus == 3">可报名</div>
          </div>
        </div>
      </div>
      <div class="title">
        <div class="line"></div>
        <div class="text">活动详情</div>
      </div>
      <div class="detail" v-html="item.activityDetail"></div>
    </div>

    <el-dialog :close-on-click-modal="false" title="报名信息" width="700" :visible.sync="saveDialogVisable" :append-to-body="true">
      <div style="margin: 0 120px">
        <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="93px" label-position="left">
          <el-form-item label="姓名" prop="userName ">
            <el-input v-model="saveData.userName" placeholder="请输入姓名" :maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="saveData.phone" placeholder="请输入手机号" :maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="人数" prop="num">
            <el-input v-model="saveData.num" placeholder="请输入人数" :maxlength="8"></el-input>
          </el-form-item>
          <el-form-item label="联系人职位" prop="post">
            <el-input v-model="saveData.post" placeholder="请输入联系人职位" :maxlength="10"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisable = false">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import validate from '@/assets/js/validate';

export default {
  name: 'activitiesSquare',
  data() {
    let validatorName = (rule, value, callback) => {
      console.log(value, validate);
      if (value === '' || value === undefined) {
        callback(new Error('请输入手机号'));
        return;
      }
      if (validate.isValidPhone(value)) {
        callback();
      } else {
        callback(new Error('正确的手机号'));
      }
    };
    return {
      item: {},
      saveData: {
        activityId: Number(this.$route.params.id),
        phone: '',
        userName: '',
        post: '',
        num: '',
      },
      saveRules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        post: [{ required: true, message: '请输入报名人职位', trigger: 'blur' }],
        num: [{ required: true, message: '请输入人数', trigger: 'blur' }],
        phone: [{ required: true, validator: validatorName, trigger: 'blur' }],
      },
      saveDialogVisable: false,
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$api.getactivity(this.$route.params.id).then((res) => {
        this.item = res.result;
      });
    },
    save() {
      this.$refs['saveForm'].validate((valid) => {
        if (valid) {
          this.$api.activitySignUp(this.saveData).then((res) => {
            this.saveDialogVisable = false;
            this.get();
            if (res.success) {
              this.$message.success({ message: '报名成功', offset: 80 });
            }else{
              this.$message.error({ message: res.message, offset: 80 });
            }
          });
        } else {
          return false;
        }
      });
    },
    signUp() {
      this.saveDialogVisable = true;
      this.$nextTick(() => {
        this.$refs['saveForm'].resetFields();
        // this.saveData = JSON.parse(JSON.stringify(this.saveInitData));
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.activitiesSquare {
  
  .content {
    padding: 20px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    .box {
      padding: 58px 42px 0;
      background: url('../../../assets/img/home_gxsp_img1@2x.jpg') center no-repeat;
      height: 110px;
      border-radius: 5px;
      margin-bottom: 200px;
    }

    .item {
      // height: 160px;
      // border-bottom: 1px solid #ddd;
      display: flex;
      padding: 30px;
      background-color: #fff;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
      .img {
        height: 160px;
        width: 276px;
        margin-right: 26px;
      }
      .info {
        flex: 1;
        .titles {
          margin-top: 12px;
          font-size: 18px;
          color: #333333;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .labels {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .label {
            background: #eaf2f9;
            border-radius: 34px;
            height: 28px;
            line-height: 28px;
            padding: 0 15px;
            font-size: 12px;
            color: #135694;
            letter-spacing: 0.3px;
            margin-right: 16px;
          }
        }
        .texts {
          font-size: 12px;
          color: #666666;
          margin-bottom: 8px;
          line-height: 16px;
        }
      }
      .item-right {
        .time {
          font-size: 12px;
          color: #666666;
          letter-spacing: 0;
          text-align: right;
          margin-top: 16px;
        }
        .top {
          text-align: right;
          margin-top: 32px;
          color: #666666;
          span {
            font-size: 18px;
            color: #151515;
          }
        }
        .rs {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          vertical-align: text-top;
        }
        .btn1 {
          background: #135694;
          border-radius: 4px;
          width: 96px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          margin-top: 20px;
          cursor: pointer;
          float: right;
        }
        .btn2 {
          font-size: 14px;
          color: #135694;
          line-height: 19px;
          margin-top: 30px;
          margin-right: 20px;
          cursor: pointer;
          text-align: right;
        }
        .btn3 {
          background: #f7f7f7;
          border: 1px solid #dddddd;
          border-radius: 4px;
          width: 96px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          color: #999999;
          margin-top: 20px;
          float: right;
        }
      }
    }
    .detail {
      font-size: 14px;
      color: #333333;
      line-height: 35px;
    }
  }
}
</style>
